<template>
  <div class="editOver-container">
    <CSDialog
      :dialogTitle="isEdit ? '所属房间' : '添加业主'"
      :dialogVisible="editCompanyOwnerVisible"
      dialogWidth="560px"
      @click="allRoomBtn"
       @onConfirm="editCompanyOwnerMethod"
      class="editOver-container oppositeName"
      @onClose="
        editCompanyOwnerVisible = false;
        isEdit = false;
        leaseSave = {};
      "
    >
      <div
        slot="dialog-content"
        style="padding: 20px 30px; font-size: 24px"
        @click="dialogBtn"
      >
        <template>
          <div style="margin-bottom: 23px" v-if="!isEdit">
            <label
              style="width: 120px; margin-right: 50px; text-align: right"
              >{{ isEdit ? "租客" : "选择业主" }}</label
            >
            <CSRadio
              v-model="searchCompanyOwner"
              :items="{ 1: '企业', 2: '个人' }"
              @input="queryInfo()"
            />
          </div>

          <div
            slot="dialog-content"
            class="room-form"
            style="margin-bottom: 20px"
            v-if="!isEdit"
          >
            <div class="field-item">
              <div class="field-item-content" style="position: relative">
                <label
                  style="width: 120px; margin-right: 50px; text-align: right"
                >
                </label>
                <CSSelect
                  height="40px"
                  iWidth="80px"
                  style="width: 300px"
                  :style="{
                    color: JSON.stringify(leaseSave) !== '{}' ? '#000' : '#999',
                  }"
                >
                  <DropDownSelection
                    @queryInfo="queryInfo"
                    @selectWarte="selectValue"
                    :dataList="modifyLeaseList"
                    :item="leaseItem"
                    :placeholderName="searchInfo"
                    type="owner"
                    leftPossion="170px"
                    :isEdit="isEdit"
                    :searchCompanyOwner = "searchCompanyOwner"
                  ></DropDownSelection>
                </CSSelect>
              </div>
            </div>
          </div>
          <!-- 选择房间 -->
          <template v-if="1 > 0">
            <label style="width: 120px; margin-right: 50px; text-align: right">
              {{ isEdit ? "房间" : "所属房间" }}
            </label>
            <AllRoomSelectOwner
              @change-rooms="changeContractRooms"
              :selectedRooms="roomIds"
              :isSelectedRooms="isSelectedRooms"
              :checkedRoomNames="checkedRoomNames"
              style="display: inline-block"
            ></AllRoomSelectOwner>
          </template>
        </template>
      </div>
    </CSDialog>


  </div>
</template>
<script>
import {
  createTenantUrl,
  searchEnterpriseIndividual,
  updateEditOwnerLessee,
} from "@/requestUrl";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import AllRoomSelectOwner from "@/components/AllRoomSelectOwner";
import { ROOM_TEMPORARY_RESIDENT } from "@/constant";
import CSRadio from "@/components/common/CSRadio";
import DropDownSelection from "@/components/DropDownSelection.vue";

const OWNER_TYPE = {
  OWNER: "1001",
  TENANT: "1003",
};

const SEARCH_HINT = {
  1: "搜索企业名称/对接人",
  2: "搜索个人名称/对接人",
};
export default {
  props: {
    notifyLoadDataComponentName: String,
    componentTitle: String,
    ownerTypeCd: String,
  },
  components: {
    CSSelect,
    CSDialog,
    AllRoomSelectOwner,
    CSRadio,
    DropDownSelection,
  },
  data() {
    return {
      leaseItem: {}, //本条信息内容,用于回显
      leaseSave: {},
      modifyLeaseList: [], //企业列表
      checkedRoomNames: [],
      isSelectedRooms: [],
      ROOM_TEMPORARY_RESIDENT,
      selectedRooms: [],
      roomIds: [],
      step: 1,
      isEdit: false,
      editCompanyOwnerInfo: {
        id: null,
        regionId: this.$vc.getCurrentRegion().communityId,
        companyName: "",
        legalEntity: "",
        businessLicense: "",
        unifiedSocialCreditIdentifier: "",
        businessAccount: "",
        deputyName: "",
        deputyIdNo: "",
        deputyPhone: "",
        deputyGender: "",
        deputyEmail: "",
        comment: "", //除了comment选填，上面都是必填
        type: 1,
        enterpriseIndividualId: "",
        rooms: [],
      },
      editCompanyOwnerVisible: false,
      searchCompanyOwner: 1,
    };
  },
  computed: {
    searchInfo() {
      return SEARCH_HINT[this.searchCompanyOwner];
    },
  },
  created() {
    this.queryInfo();
  },
  mounted() {
    this.$vc.on(
      this.$route.path,
      "EditCompanyTenantOver",
      "show",
      this.changeModalStatus
    );
  },
  methods: {
    //查询对接人
    queryInfo(search = "") {
      this.$fly
        .post(searchEnterpriseIndividual, {
          search,
          type: this.searchCompanyOwner,
          regionCode: this.$vc.getCurrentRegion().communityId,
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          this.modifyLeaseList = res.data;
        });
    },
    //选中的对接人
    selectValue(item) {
      this.leaseSave = item;
    },

    dialogBtn() {
      this.$vc.emit("allRoomSelectContract", "selcet", {});
    },
    //点击面板，控制显示隐藏
    allRoomBtn() {
      this.$vc.emit("allRoomSelectOwner", "selcet", {});
    },
    /**
     * 修改签约合同的房间
     * @param {Object} roomInfo 选中的所有房间信息
     *  */
    changeContractRooms(roomInfo) {
      const { roomIds, rooms } = roomInfo;
      this.contractRooms = roomIds.map((val) => {
        return { roomId: val };
      });
      let arr = rooms.map((item) => {
        return item.area;
      });
      if (arr.length > 0) {
        this.editCompanyOwnerInfo.builtUpAread = arr.reduce((pre, cur) => {
          return pre + cur;
        });
      }
      this.selectedRooms = rooms.map((item) => {
        return {
          buildingId: item.buildingId,
          buildingName: item.buildingName,
          floor: item.floor,
          roomId: item.roomId,
          roomName: item.roomName,
          area: item.area,
        };
      });
    },
    uploadEditCompanyPhoto() {
      $("#uploadEditCompanyOwnerPhoto").trigger("click");
    },
    async uploadPhoto(base64) {
      return new Promise((reslove, reject) => {
        this.$vc.http.post(
          "uploadImg",
          "upload",
          {
            img: base64,
          },
          {
            headres: {
              "Content-Type": "application/json",
            },
          },
          (resStr, res) => {
            reslove(res.body.fileSaveName);
          },
          (errText, err) => {
            reject(errText);
          }
        );
      });
    },
    chooseEditCompanyBusinessLicense(event) {
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        var file = photoFiles[0]; // 文件大小校验的动作
        if (file.size > 1024 * 1024 * 2) {
          this.$vc.toast("图片大小不能超过 2MB!");
          return false;
        }
        var reader = new FileReader(); //新建FileReader对象
        reader.readAsDataURL(file); //读取为base64
        reader.onloadend = async (e) => {
          this.editCompanyOwnerInfo.businessLicense = await this.uploadPhoto(
            reader.result
          ).catch((err) => {
            console.error("上传营业执照失败");
            return "";
          });
        };
      }
    },
    changeModalStatus(tenant) {
      this.roomIds = [];
      this.editCompanyOwnerVisible = true;
      this.step = 1;
      if (tenant !== null) {
        console.log(2222);
        this.checkedRoomNames = [];
        setTimeout(() => {
          this.roomIds = tenant.roomIds;
          this.isSelectedRooms = tenant.roomIds;
        }, 0);

        this.isEdit = true;
        this.editCompanyOwnerInfo.unifiedSocialCreditIdentifier = tenant.ucsi;
        this.$vc.copyObject(tenant, this.editCompanyOwnerInfo);
        this.editCompanyOwnerInfo.deputyGender =
          tenant.deputyGender === "男" ? 1 : 2;
        return;
      }
      this.resetCompanyEditOwnerInfo();
    },
    resetCompanyEditOwnerInfo() {
      this.checkedRoomNames = [];
      this.selectedRooms = [];
      this.editCompanyOwnerInfo = {
        id: null,
        regionId: this.$vc.getCurrentRegion().communityId,
        companyName: "",
        legalEntity: "",
        businessLicense: "",
        unifiedSocialCreditIdentifier: "",
        businessAccount: "",
        deputyName: "",
        deputyIdNo: "",
        deputyGender: "",
        deputyEmail: "",
        deputyPhone: "",
        comment: "", //除了comment选填，上面都是必填
        type: 1,
        enterpriseIndividualId: "",
        rooms: [],
      };
    },
    createCompanyOwnerInfo() {
      const params = {
        ...this.editCompanyOwnerInfo,
        rooms: this.selectedRooms.map((res) => {
          return {
            buildingId: res.buildingId, //楼栋code
            buildingName: res.buildingName, //楼栋名称
            floor: res.floor, //楼层
            roomId: res.roomId, //房间id
            roomName: res.roomName, //房间名称
          };
        }),
      };
      delete params.id;
      delete params.builtUpAread;

      this.$fly.post(createTenantUrl, params).then((res) => {
        if (res.code != 0) {
          return;
        }
        this.$vc.toast("添加成功");
        this.editCompanyOwnerVisible = false;
        this.$vc.emit(this.$route.path, "listCompanyOwnerData", {});
      });
    },
    updateCompanyOwnerInfo() {
      delete this.editCompanyOwnerInfo.type;
      delete this.editCompanyOwnerInfo.builtUpAread;
      this.$fly
        .post(updateEditOwnerLessee, {
          ...this.editCompanyOwnerInfo,
          rooms: this.selectedRooms.map((res) => {
            return {
              buildingId: res.buildingId, //楼栋code
              buildingName: res.buildingName, //楼栋名称
              floor: res.floor, //楼层
              roomId: res.roomId, //房间id
              roomName: res.roomName, //房间名称
            };
          }),
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          this.$vc.toast("修改成功");
          this.editCompanyOwnerVisible = false;
          this.$vc.emit(
            this.$route.path,
            this.notifyLoadDataComponentName,
            "listCompanyOwnerData",
            {}
          );
        });
    },
    editCompanyOwnerMethod() {
      if (
        !this.editCompanyOwnerInfo.id ||
        this.editCompanyOwnerInfo.id == null
      ) {
        this.addCompanyOwner();
      } else {
        this.editCompanyOwner();
      }
    },

    /*新加 添加业主 */
    addCompanyOwner() {
      let params = {
        regionId: this.$vc.getCurrentRegion().communityId,
        type: 1,
        id: this.leaseSave.id,
        rooms: this.selectedRooms,
      };
      this.$fly.post(createTenantUrl, params).then((res) => {
        if (res.code !== 0) {
          return;
        }
        this.$vc.toast("添加成功");
        this.editCompanyOwnerVisible = false;
        this.$vc.emit(this.$route.path, "listCompanyOwnerData", {});
      });
    },
    /*新加 修改业主 */
    editCompanyOwner() {
      let params = {
        regionId: this.$vc.getCurrentRegion().communityId,
        EIId: this.editCompanyOwnerInfo.enterpriseIndividualId,
        id: this.editCompanyOwnerInfo.id,
        rooms: this.selectedRooms,
      };
      this.$fly.post(updateEditOwnerLessee, params).then((res) => {
        if (res.code !== 0) {
          return;
        }
        this.$vc.toast("修改成功");
        this.editCompanyOwnerVisible = false;
        this.$vc.emit(this.$route.path, "listCompanyOwnerData", {});
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.modal-body {
  input {
    &::placeholder {
      color: #999;
    }

    font-size: 24px;
    border-radius: 6px;
    height: 40px;
    box-sizing: border-box;
  }
}

.step-btn-wrap {
  .btn {
    min-width: 200px;
    min-height: 40px;
    padding: 0 12px;
    border-radius: 6px;
    font-size: 24px;

    &:last-of-type {
      margin-left: 60px;
    }
  }
}

.col-form-label {
  width: 195px;
  padding-left: 0;
  padding-right: 0;
  font-size: 24px;
  text-align: right;
  margin-right: 30px;
}

input, select {
  padding: 0 10px;
}

textarea {
  padding: 6px 10px;
}

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-ms-input-placeholder {
  color: #999;
}
</style>

